import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../../config/config';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { List } from 'src/app/pages/entidades/entidades.component';



@Injectable({
    providedIn: 'root'
})
export class CargabeneficiariosService {

    constructor(public http: HttpClient) { }



    cargarBeneficiarios(IdIndicador: number, IdRegionalizacion: number, archivo: string): any {

        const parametros = {
            IdIndicador: IdIndicador,
            IdRegionalizacion: IdRegionalizacion,
            archivo: archivo
        };
        
        debugger;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = URL_SERVICIOS + 'ApiRest/Basicos/CargarExcelBeneficiarios';

        this.http.post(url, parametros,{ headers: headers }).subscribe(
            (data:any) => {
                // Manejar la respuesta exitosa aquí
                alert(data.Mensaje);
                console.log(data);
            },
            (error) => {
                // Manejar el error aquí
                console.error('Error al realizar la solicitud HTTP:', error);
            }
        )

    }
}
