import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewHomeService } from 'src/app/services/new-home/new-home.service';

@Component({
  selector: 'app-detalle-noticias',
  templateUrl: './detalle-noticias.component.html',
  styleUrls: ['./detalle-noticias.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css']
})
export class DetalleNoticiasComponent implements OnInit {

  constructor(public _newHomeService: NewHomeService,
    private activatedRoute: ActivatedRoute) { }

  listaNoticias = [];
    noticia: {};


  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      this.cargarNoticia(id);
      this.cargarNoticias(id);
      });
  }

  cargarNoticias(id) {
    this._newHomeService.getNoticias().subscribe((resp: any) => {
      this.listaNoticias = resp.Lista.filter( item => item.Id.toString() !== id ).slice(0,2);
    });
  }

  cargarNoticia(id) {
    this._newHomeService.getNoticiaXid(id).subscribe((resp: any) => {
      this.noticia = resp;
    });
  }

}
