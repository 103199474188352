import { Component, OnInit } from '@angular/core';
import { NewHomeService } from 'src/app/services/new-home/new-home.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css']
})
export class NoticiasComponent implements OnInit {

  constructor(public _newHomeService: NewHomeService,) { }

  listaNoticias = [];


  ngOnInit() {
    this.cargarNoticias();
  }

  cargarNoticias() {
    this._newHomeService.getNoticias().subscribe((resp: any) => {
      this.listaNoticias = resp.Lista;
    });
  }


}
