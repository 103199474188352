import { Component, OnInit } from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { NewHomeService } from 'src/app/services/new-home/new-home.service';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css']
})
export class CalendarioComponent implements OnInit {

  fecha: string 
  listaEventos = [];
  objEvento;
  popupVisible: boolean = false;

  constructor(
    public _newHomeService: NewHomeService,
  ) { }

  ngOnInit() {
    const date = new Date().toLocaleDateString();
    this.fecha = date;
    this.cargarEventos(date);
  }

 


  cargarEventos(event) {
    const strDate = event.split('/')[2] +'-'+ event.split('/')[1] +'-'+ event.split('/')[0]; 
    this._newHomeService.getEventosXfecha(strDate, strDate).subscribe((resp: any) => {
      this.listaEventos = resp;
    });
  }


  obtenerNombreMes(numero) {
    numero = Number(numero);
    let miFecha = new Date();
    if (0 < numero && numero <= 12) {
      miFecha.setMonth(numero - 1);
      return (new Intl.DateTimeFormat('es-ES', { month: 'short'}).format(miFecha)).toUpperCase();
    } else {
      return null;
    }
  }

  showModal(objEvento) {
    this.objEvento = objEvento;
    this.popupVisible = true;
  } 

  popupHiding(){
    this.popupVisible = false;

  }

}
