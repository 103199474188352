import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editorial',
  templateUrl: './editorial.component.html',
  styleUrls: ['./editorial.component.css','../../../../assets/css/css.css']
})
export class EditorialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
