import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import {
  AgenteService,
  TipoReferenciaService,
  UsuarioService,
} from 'src/app/services/service.index';
import {
  Agente,
  AgenteExperiencia,
  AgenteFormacion,
} from 'src/app/models/agente.model';
import { IOption } from 'ng-select';
// import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { URL_SERVICIOS } from '../../../config/config';
import { ValorReferencia, Formacion } from '../../../models/valorReferencia.model';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { User } from '../../../models/user.model';
import { Session } from '../../../models/session.model';
import { CurrencyPipe } from '@angular/common';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { DxPivotGridComponent, DxChartComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import { OtrasAreasOcupaciones, OtrasAreas, OtrasOcupaciones, } from '../../../models/agente.model';
import { SoyCulturaService } from 'src/app/services/actores/soy-cultura/soy-cultura.service';
import { AgenteCultura } from 'src/app/models/agenteCultura.model';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'soy-cultura',
  templateUrl: './soy-cultura.component.html',
  styleUrls: ['./soy-cultura.component.css'],
})
export class SoyCulturaComponent implements OnInit {

  // #region Variables Globales
  @ViewChild('ngxLoading', { static: false })
  ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', { static: false })
  customLoadingTemplate: TemplateRef<any>;
  @ViewChild(DxPivotGridComponent, { static: false })
  pivotGrid: DxPivotGridComponent;
  gridOtrasOcupaciones: dxDataGrid;
  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryRed;
  public secondaryColour = SecondaryBlue;
  public coloursEnabled = true;
  public loadingTemplate: TemplateRef<any>;
  // tslint:disable-next-line: max-line-length
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  loading = false;
  dataSource: any;
  usuario: User;
  usuarioNombre: string;
  email: string;
  roles: any;
  role: string;
  usuId: number;
  perfilId: number;
  currentSession: Session;
  gestionaActores: boolean;
  mostrarPuntaje: boolean;
  gestionaActoresHtml: string;
  tasks: DataSource;
  tasks1 = [];

  datos = [];

  listaAgentes = [];
  listOcupacion: any;
  listaAgentesExperiencia = [];
  listaAgentesFormacion = [];
  listaAgentesOcupacion = [];
  displayListAgente: boolean;
  displayCrearAgente: boolean;
  registro: boolean;
  experiencia: boolean;
  displayListAgenteExperiencia: boolean;
  displayCrearAgenteExperiencia: boolean;

  formacion: boolean;
  ocupacion: boolean;
  displayListAgenteFormacion: boolean;
  displayCrearAgenteFormacion: boolean;

  displayListaAgenteOcupacion: boolean;
  agente: Agente;
  agenteExperiencia: AgenteExperiencia;
  agenteFormacion: AgenteFormacion;
  tipoDoc: Array<IOption>;
  tipoDiscapacidad: Array<IOption>;
  depLista: Array<IOption>;
  paisesLista: Array<IOption>;
  paisesResidenciaLista: Array<IOption>;
  munLista: Array<IOption>;
  areaLista: Array<IOption>;
  seguridadSocialLista: Array<IOption>;
  tipoExperienciaLista: Array<IOption>;
  nivelFormacionLista: Array<IOption>;
  origenInfoLista: Array<IOption>;
  EstadoLista: Array<IOption>;
  ocupacionLista: Array<ValorReferencia>;
  checkedList = [];
  munNacimientoLista: Array<IOption>;
  ZON_ID_DEP: string;
  DepartamentoNacimientoId: string;
  operacion = 'C';
  title: string;
  value: any[] = [];
  valueExperiencia: any[] = [];
  valueFormacion: any[] = [];
  url: string;
  error: any;
  EstiloStep1: string;
  EstiloStep2: string;
  EstiloStep3: string;
  EstiloStep4: string;

  urlImagen: string;
  urlImagenExperiencia: string;
  urlImagenFormacion: string;
  oculto = 'oculto';
  ocultoExperiencia = 'oculto';
  ocultoFormacion = 'oculto';

  maxDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  selectedItemsAreaPrincipal: any[] = [];

  public dataSourceOtrasOcupaciones: OtrasAreasOcupaciones[];


  otrasAreas: OtrasAreas[] = [
    {
      'OtrasAreasId': 3514,
      'nombre': 'Música'
    },
    {
      'OtrasAreasId': 3515,
      'nombre': 'Teatro'
    },
    {
      'OtrasAreasId': 3513,
      'nombre': 'Literatura y Editorial'
    },
    {
      'OtrasAreasId': 3509,
      'nombre': 'Artes Visuales'
    },
    {
      'OtrasAreasId': 3512,
      'nombre': 'Danza'
    }
    ,
    {
      'OtrasAreasId': 3350,
      'nombre': 'Circo'
    }
    ,
    {
      'OtrasAreasId': 3510,
      'nombre': 'Cine y Audiovisuales'
    }
  ];

  otrasOcupaciones: OtrasOcupaciones[];

  public agenteCultura: AgenteCultura;
  constructor(
    public _agenteService: AgenteService,
    public _soyCulturaService: SoyCulturaService,
    private currencyPipe: CurrencyPipe,
    public _tipoReferenciaService: TipoReferenciaService,
    public _usuarioService: UsuarioService
  ) {
    this.selectedItemsAreaPrincipal = [];
    this.dataSourceOtrasOcupaciones = [];
    this.tasks = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: this.tasks1
      })
    });
    this.customizeTooltip = this.customizeTooltip.bind(this);
    const user: any = this._usuarioService.getCurrentUser();
    this.usuario = JSON.parse(user);
    this.usuarioNombre = this.usuario.USU_NOMBRE;
    this.email = this.usuario.USU_CORREO_ELECTRONICO;
    this.usuId = this.usuario.USU_ID;
    this.roles = this.usuario.Perfiles;
    if (this.roles.length > 0) {
      this.role = this.roles[0].PER_NOMBRE;
      this.perfilId = this.roles[0].PER_ID;
      if (this.roles[0].GestionaActores !== null) {
        if (this.roles[0].GestionaActores) {
          this.gestionaActores = this.roles[0].GestionaActores;
          this.oculto = '';
        } else {
          this.oculto = 'oculto';
          this.gestionaActores = false;
        }
      } else {
        this.gestionaActores = false;
      }

      this.getFilteredOcupaciones = this.getFilteredOcupaciones.bind(this);

    }

    this.mostrarPuntaje = false;
    this.displayListAgente = true;
    this.displayCrearAgente = false;
    this.registro = true;
    this.experiencia = false;
    this.displayListAgenteExperiencia = true;
    this.displayCrearAgenteExperiencia = false;

    this.formacion = false;
    this.ocupacion = false;
    this.displayListAgenteFormacion = true;
    this.displayListaAgenteOcupacion = true;
    this.displayCrearAgenteFormacion = false;

    // tslint:disable-next-line: max-line-length
    this.checkedList = [];
    this.datos = []; 
    this.EstiloStep1 = 'active';
    this.EstiloStep2 = '';
    this.EstiloStep3 = '';
    this.EstiloStep4 = '';
  }
  //#endregion

  setAreasValue(rowData: any, value: any): void {
    rowData.idValorReferencia = null;
    (<any>this).defaultSetCellValue(rowData, value);
  }

  setOtrasAreasValue(rowData: any, value: any): void {
    rowData.TipoOcupacionId = null;
    (<any>this).defaultSetCellValue(rowData, value);
  }

  getFilteredOcupaciones(options) {
    return {
      store: this.otrasOcupaciones,
      filter: options.data ? ['OtrasAreasId', '=', options.data.OtrasAreasId] : null
    };
  }

  onEditorPreparingOtrasOcupaciones(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'TipoOcupacionId') {
      e.editorOptions.disabled = (typeof e.row.data.OtrasAreasId !== 'number');
    }
  }

  customizeTooltip(args) {
    return {
      html:
        args.seriesName +
        ' | Total<div class=\'currency\'>' +
        args.valueText +
        '</div>',
    };
  }

  ngAfterViewInit() {
    this.pivotGrid.instance.bindChart(this.chart.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });

    setTimeout(() => {
      // tslint:disable-next-line:prefer-const
      let dataSource = this.pivotGrid.instance.getDataSource();
      // dataSource.expandHeaderItem('row', ['North America']);
      // dataSource.expandHeaderItem('column', [2013]);
    }, 0);
    // this.cargarPrograma();
  }

  ngOnInit() {
    this.cargarAgentes();
  }


  showPanel2(sw: string, id: any) {
    this.registro = false;
    this.experiencia = false;
    this.formacion = false;
    this.ocupacion = false;
    if (sw === 'R') {
      // Crear Usuario
      this.registro = true;
      this.EstiloStep1 = 'active';
      this.EstiloStep2 = '';
      this.EstiloStep3 = '';
      this.EstiloStep4 = '';
    } else if (sw === 'P' && this.agenteCultura.AgenteNumeroIdentificacion != null) {
      // Actualizar Usuario
      this.experiencia = true;
      this.EstiloStep1 = 'complete';
      this.EstiloStep2 = 'active';
      this.EstiloStep3 = '';
      this.EstiloStep4 = '';
    } else if (sw === 'O' &&  this.agenteCultura.AgenteNumeroIdentificacion != null) {
      // Volver al listado
      this.formacion = true;
      this.EstiloStep1 = 'complete';
      this.EstiloStep2 = 'complete';
      this.EstiloStep3 = 'active';
      this.EstiloStep4 = '';
    }  else if (sw === 'OC' &&  this.agenteCultura.AgenteNumeroIdentificacion != null) {
      // Volver al listado
      this.ocupacion = true;
      this.EstiloStep1 = 'complete';
      this.EstiloStep2 = 'complete';
      this.EstiloStep3 = 'complete';
      this.EstiloStep4 = 'active';
    }
    else if (this.agente.AgenteId === null) {
      this.registro = true;
    }
  }

 
  cargarAgentes() {
    this.loading = true;
    this._soyCulturaService
      .cargarAgentes(this.usuId, this.perfilId)
      .subscribe((resp: any) => {
        this.listaAgentes = resp.lista.informacionPersonal;
        console.log(this.listaAgentes)
        this.loading = false;
      });
  }

  showPanel(sw: string, id: any, tipoIdentificacion: string, sistema: string) {
    if (sw === 'C') {
      // Crear Usuario
      this.displayListAgente = false;
      this.displayCrearAgente = true;
      this.inicializarModelo();
      this.experiencia = false;
      this.formacion = false;
      this.ocupacion = false;
      this.registro = true;      
      this.EstiloStep1 = 'active';
      this.EstiloStep2 = '';
      this.EstiloStep3 = '';
      this.EstiloStep4 = '';
    } else if (sw === 'U') {     
      this.displayListAgente = false;
      this.displayCrearAgente = true;
      this.getAgenteById(id, tipoIdentificacion);
      this.operacion = 'U';
      this.experiencia = false;
      this.formacion = false;
      this.registro = true;
      this.EstiloStep1 = 'active';
      this.EstiloStep2 = '';
      this.EstiloStep3 = '';
      this.EstiloStep4 = '';


    } else if (sw === 'V') {
      //Volver al listado de contratistas
      this.displayListAgente = true;
      this.displayCrearAgente = false;
      this.inicializarModelo();
      // recargar la tabla
      this.cargarAgentes();
    }
  }
  getAgenteById(id: any, tipoIdentificacion: any) {
    this.loading = true;
    this._soyCulturaService.cargarAgentePorId(id, tipoIdentificacion).subscribe(
      (result: any) => {
        this.agenteCultura = {
          AgenteNumeroIdentificacion: id,
          InformacionPersonal: result.lista.informacionPersonal[0],
          Ocupaciones: result.lista.ocupaciones,
          Formaciones: result.lista.formaciones, Experiencias: result.lista.experiencias
        }
        this.listaAgentesExperiencia = this.agenteCultura.Experiencias
        this.listaAgentesFormacion = this.agenteCultura.Formaciones
        this.listaAgentesOcupacion = this.agenteCultura.Ocupaciones
        this.loading = false;
      },
      (error) => {
        console.log(<any>error);
      }
    );
  }

  inicializarModelo() {
    this.agente = {
      AgenteId: null,
      TipoIdentificacionId: null,
      AgenteTipoId: 146,
      AgenteNumeroIdentificacion: '',
      AgentePrimerNombre: '',
      AgenteSegundoNombre: '',
      AgentePrimerApellido: '',
      AgenteSegundoApellido: '',
      AgenteSexoId: null,
      AgenteDireccion: '',
      AgenteTelefonoFijo: '',
      AgenteTelefonoMovil: '',
      AgenteEmail: '',
      AgenteFechaRegistro: '',
      MunicipioResidenciaId: null,
      AgenteFechaAprobacion: '',
      AgenteObservaciones: '',
      AgenteFechaEdito: '',
      CuentaUsuarioIdEdito: null,
      CuentaUsuarioIdGestiona: null,
      CuentaUsuarioIdCreacion: null,
      AgenteEstadoId: 197,
      AgenteFechaNacimiento: '',
      MunicipioNacimientoId: null,
      DepartamentoResidenciaId: null,
      DepartamentoNacimientoId: null,
      TipoOcupacion: null,
      AgenteUrl: '',
      AgenteAlias: '',
      AreaId: null,
      AgenteOrigenInformacionId: null,
      Fotografia: '',
      AgentePoseeAlgunaDiscapacidad: false,
      DiscapacidadId: null,
      PefilFacebook: '',
      PerfilTwitter: '',
      PaginaWeb: '',
      CanalYoutube: '',
      PerfilSoundCloud: '',
      PerfilFlickr: '',
      PerfilInstagram: '',
      EstadoNombre: '',
      AgenteEstrato: null,
      AgentePuntajeSisben: null,
      AgenteIngresosMensuales: null,
      AgenteSeguridadSocialId: null,
      PaisIdNacimiento: null,
      PaisIdResidencia: null,
      AgenteCiudadNacimiento: '',
      AgenteCiudadresidencia: '',
      OtrasAreasOcupacion: null,
      AgenteTrabajoEnCalleRecurrentemente: false,
    };


    this.urlImagen = '';
    this.urlImagenExperiencia = '';

    this.listaAgentesExperiencia = null;
    this.listaAgentesFormacion = null;
    this.displayCrearAgenteExperiencia = false;
    this.displayListAgenteExperiencia = true;
    this.displayCrearAgenteFormacion = false;
    this.displayListAgenteFormacion = true;
    this.displayListaAgenteOcupacion = true;
    this.datos = [];
    this.selectedItemsAreaPrincipal = [];
    this.dataSourceOtrasOcupaciones = [];
    this.tasks = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: this.tasks1
      })
    });
  }
}
