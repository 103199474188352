export class Catalogo {
    constructor(
        public CatalogoEditorialId: number,
        public AreaId: number,
        public CategoriaId: number,
        public SubcategoriaId: number,
        public SubSubCategoriaId: number,
        public TipoPublicacionId: number,
        public AmbitoRegionalId: number,
        public CatalogoFechaAutorizacionInicio: string,
        public CatalogoFechaAutorizacionFin: string,
        public CatalogoEditorialTitulo: string,
        public CatalogoEditorialAutorCorporativo: string,
        public CatalogoEditorialResumen: string,
        public CatalogoEditorialPalabrasClave: string,
        public CatalogoEditorialAutores: string,
        public AnoId: number,
        public CatalogoEditorialPaginas: number,
        public CatalogoEditorialISBN: string,
        public CatalogoEditorialISSN: string,
        public CatalogoEditorialISMN: string,
        public CatalogoEditorialDescripcionFisica: string,
        public CatalogoEditorialUbicacion: string,
        public CatalogoEstadoId: string,
       public ArchivoLink: string,
       public CuentaUsuarioIdCreacion: number,
       public NombreArea: string,
       public NombreCategoria: string,
       public NombreSubCategoria: string,
       public DominioPublico: boolean,
       public Practica: any[],
       public retencionId : number,
       public NombreRetencion: string,
       ) { }

}

export class Archivos {
    constructor(
        public CatalogoEditorialArchivoId: number,
        public CatalogoEditorialId: number,
        public ArchivoId: number,
        public ArchivoTipoId: number,
        public ArchivoTitulo: string,
        public ArchivoDuracionAudio: string,
        public ArchivoNombreArchivo: string,
        public ArchivoLink: string,
        public ArchivoEstadoId: number,
        public ArchivoFechaRegistro: string,
        public ArchivoUsuarioIdCreacion: number,
        public NombreTipoArchivo: string
    ) {}
}
