import { Injectable } from '@angular/core';
import { CLAVE_SERVICIOS_SOY_CULTURA_TEST, URL_SERVICIOS_SOY_CULTURA_TEST, USUARIO_SERVICIOS_SOY_CULTURA_TEST } from '../../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Agente, AgenteExperiencia, AgenteFormacion } from '../../../models/agente.model';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AgenteCulturaTipoIdentificacion } from 'src/app/models/agenteCultura.model';


@Injectable({
  providedIn: 'root'
})
export class SoyCulturaService {
  public AgenteId = 0;

  constructor(public http: HttpClient) { }

 
  cargarAgentes(usuId, perfilId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    const params = {'opcion': '2', 'TipoDcto':'', 'Identificacion':'', 'Usuario':'pcvargas@mincultura.gov.co', 'Clave':'79737241', 'IdArea': ""};
    const url = URL_SERVICIOS_SOY_CULTURA_TEST + 'Consulta/InformacionPersonal' 
    return this.http.post(url, params, { headers: headers });
  }

  cargarAgentePorId(idUsuario, tipoIdentificacion){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    console.log('PASA TIPOID', tipoIdentificacion)
    const tipoID = AgenteCulturaTipoIdentificacion[tipoIdentificacion];
    console.log('PASA TIPOID2', tipoID)
    const params = {'opcion': '1', 'TipoDcto': tipoID, 'Identificacion': idUsuario, 'Usuario': USUARIO_SERVICIOS_SOY_CULTURA_TEST, 'Clave':CLAVE_SERVICIOS_SOY_CULTURA_TEST, 'IdArea': ""};
    const url = URL_SERVICIOS_SOY_CULTURA_TEST + 'Consulta/InformacionPersonal' 
    return this.http.post(url, params, { headers: headers });
  }



}
