import { Component, OnInit } from '@angular/core';
import { CompromisosService, TipoReferenciaService, UsuarioService } from 'src/app/services/service.index';
import { Compromisos, CompromisosAvances, CompromisosAvancesArchivos } from '../../models/compromisos.model';
import { List } from '../oferta-administracion/oferta-administracion.component';
import { IOption } from 'ng-select';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';
import { URL_SERVICIOS } from '../../config/config';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'app-compromisos-administracion',
  templateUrl: './compromisos-administracion.component.html',
  styleUrls: ['./compromisos-administracion.component.css']
})
export class CompromisosAdministracionComponent implements OnInit {
  gestionaCompromisos: boolean;
  ocultoExperiencia = 'oculto';
  error: any;
  loading = false;
  urlImagenExperiencia: string;
  urlArchivoCompromisoAvance: string;
  valueExperiencia: any[] = [];
  valueExperienciaAvance: any[] = [];
  url: string;
  usuario: User;
  usuarioNombre: string;
  email: string;
  roles: any;
  role: string;
  usuId: number;
  perfilId: number;
  accionCompromiso: string;
  listaUsuarios: any;
  listaEstado: Array<IOption>;
  listaCompromisoTipo: Array<IOption>;
  listaTipoReporte: Array<IOption>;
  listaJefesDependencia: Array<IOption>;
  depLista: Array<IOption>;
  munLista: Array<IOption>;
  AreaId: string;
  DireccionId: string;
  direccionesLista: Array<IOption>;
  areasLista: Array<IOption>;
  listaImportancia: Array<IOption>;
  compromisos: Compromisos[];
  compromiso: Compromisos;
  compromisosAvances: CompromisosAvances[];
  compromisosAvance: CompromisosAvances;
  AnoId = 185;
  AnosLista: Array<IOption>;
  startEditAction: string = 'click';
  selectTextOnEditStart: boolean = true;
  popupCompromisos: boolean;
  popupCompromisosAvances: boolean;
  dataSourceAnos: List[] = [{
    'ID': 183,
    'Name': '2021'
  }, {
    'ID': 184,
    'Name': '2022'
  }];
  listaCompromisoArchivo = [];
  compromisosAvancesArchivos: CompromisosAvancesArchivos;
  valueArchivo: any[] = [];
  popupVisibleRegistroArchivo: boolean = false;
  urlArchivo: string;
  displayCompromisosAvancesArchivo = false;

  constructor(public _compromisoService: CompromisosService, public _tipoReferenciaService: TipoReferenciaService, public _usuarioService: UsuarioService) {
    this.compromiso = new Compromisos(null, null, null, null, null, null, '', '', null, null, '', '', '', '', null, null, '', '', null, null, '', null);
    this.compromisosAvance = new CompromisosAvances(null, null, '', null, null, null, '', '', '', '', '');
    this.popupCompromisos = false;
    this.popupCompromisosAvances = false;
    this.DireccionId = '0';
    this.AreaId = '0';
    const user: any = this._usuarioService.getCurrentUser();
    this.usuario = JSON.parse(user);
    this.usuarioNombre = this.usuario.USU_NOMBRE;
    this.email = this.usuario.USU_CORREO_ELECTRONICO;
    this.usuId = this.usuario.USU_ID;
    this.roles = this.usuario.Perfiles;
    if (this.roles.length > 0) {
      this.role = this.roles[0].PER_NOMBRE;
      this.perfilId = this.roles[0].PER_ID;
      if (this.roles[0].GestionaCompromisos !== null) {
        if (this.roles[0].GestionaCompromisos) {
          this.gestionaCompromisos = this.roles[0].GestionaCompromisos;
          // this.oculto = '';
        } else {
          // this.oculto = 'oculto';
          this.gestionaCompromisos = false;
        }
      } else {
        this.gestionaCompromisos = false;
      }
    }
  }

  ngOnInit() {
    this.getUsuariosInternos();
    this.cargarTiposCompromisos();
    this.cargarEstado();
    this.cargarTipoReporte();
    this.cargarImportancia();
    this.cargarJefesDependencia();
    this.getDirecciones();
    this.getDep();
    this.getAnos();
    this.getAllCompromisos();

  }

  getAnos() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(16).subscribe(
      result => {
        const currentYear = new Date().getFullYear();
        this.AnosLista = result.Lista.sort((a, b) => parseInt(b.nombre) - parseInt(a.nombre));
        this.AnoId = result.Lista.find(e => parseInt(e.nombre) === currentYear).idValorReferencia


      },
      error => {
        console.log(<any>error);
      }
    );
  }


  addIdParameterPdf(e: any) {
    this.url = URL_SERVICIOS + '/ApiRest/Compromisos/MediaUploadPdf';
  }

  onSubmitCompromisoAvance(isValid: any) {
    if (isValid) {


      this.compromisosAvance.UsuarioRegistra = this.usuId;
      // if (this.accionCompromiso === 'U') {
      //   this.compromiso.FechaActualizada = Date..now().toString();
      // } else {
      //   this.compromiso.FechaRegistro = Date.now().toString();
      // }rr
      this._compromisoService
        .salvarCompromisoAvance(this.compromisosAvance)
        .subscribe((resp) => {
          this.popupCompromisosAvances = false;
          this.CargarCompromisosAvances(this.compromisosAvance.CompromisoId);
          this.getAllCompromisos();
          //this.compromisosAvances = [];
        });
    }
  }

  inicializarAvanceCompromiso(compromiso: Compromisos) {

    this.compromisosAvance = {
      ReporteAvanceId: null,
      CompromisoId: compromiso.CompromisoId,
      AvanceCualitativo: '',
      AvanceCuantitativo: null,
      EstadoId: null,
      FechaActualiza: '',
      FechaRegistra: '',
      UsuarioRegistra: null,
      Compromiso: compromiso.Temas,
      DescripcionCompromiso: compromiso.Descripcion,
      NombreArchivo: ''
    };
    this.urlArchivoCompromisoAvance = ''
    this.valueExperienciaAvance = []
  }

  borrarAvanceCompromiso(ReporteAvanceId, CompromisoId) {
    Swal.fire({
      title: 'Borrar Avance Compromiso?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        this._compromisoService.deleteCompromisoAvance(ReporteAvanceId).subscribe(
          (result) => {
            // if (result.OperacionExitosa) {
            //     this.getRedSocialByAgente();
            //     this.servicePNotify.warn('Eliminar', 'Datos eliminados correctamente!');
            // } else {
            //     this.servicePNotify.html(
            //         '<h4>Error al guardar los datos</h4><p>' + result.Mensaje + '</p>',
            //         'error'
            //     );
            // }
            // se puede utilizar esta variable para controler los mensajes del back
            //console.log(result.OperacionExitosa);
            if (result.OperacionExitosa) {
              Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
            }
            else {
              Swal.fire('ERROR!', 'Ocurrio un error al borrar el registro', 'error');
            }
            debugger;
            this.CargarCompromisosAvances(CompromisoId);
          },
          (error) => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            // swal('Agente Creado', resp.Mensaje, 'success' );
            console.log(<any>error);
          }
        );


      }
    });
  }


  showAccionCompromisoAvance(data: any, accion: string) {
    debugger;
    console.log("accionAvance", data);
    this.compromisosAvance.CompromisoId = data.CompromisoId;
    if (accion === 'C') {
      this.inicializarAvanceCompromiso(data);
      this.displayCompromisosAvancesArchivo = false;
    }
    else if (accion === 'U') {
      this._compromisoService
        .getCompromisoAvanceByReporteAvanceId(data.ReporteAvanceId)
        .subscribe((resp: any) => {
          this.compromisosAvance = resp;
          this.urlArchivoCompromisoAvance = this.compromisosAvance.NombreArchivo;
          this.getCompromisosArchivos(this.compromisosAvance.ReporteAvanceId);
        });
      this.displayCompromisosAvancesArchivo = true;
    }
    this.accionCompromiso = accion;
    this.popupCompromisosAvances = true;
  }


  getCompromisosArchivos(reporteAvanceId: number) {
    this._compromisoService.getArchivoById(reporteAvanceId).subscribe((resp: any) => {
      this.listaCompromisoArchivo = resp.Lista;
    });
  }

  showVolverAvance() {
    this.popupCompromisosAvances = false;
  }


  getDep() {
    this._tipoReferenciaService.getDepartamentos().subscribe(
      (result) => {
        // debugger;
        this.depLista = result.Lista;
      },
      (error) => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  getAllCompromisos() {
    debugger;
    this._compromisoService.getAllCompromisos(this.AnoId, this.usuId, this.gestionaCompromisos)
      .subscribe((resp: any) => {
        this.compromisos = resp.Lista;

      },
        error => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );

    this.compromisosAvances = [];
  }

  CargarCompromisosAvances(id: any) {
    this._compromisoService.getAllCompromisosAvances(id).subscribe((resp: any) => {
      this.compromisosAvances = resp.Lista;
    });
  }

  onSubmitCompromiso(isValid: any) {
    if (isValid) {


      this.compromiso.UsuarioRegistraId = this.usuId;
      // if (this.accionCompromiso === 'U') {
      //   this.compromiso.FechaActualizada = Date..now().toString();
      // } else {
      //   this.compromiso.FechaRegistro = Date.now().toString();
      // }
      this._compromisoService
        .salvarCompromiso(this.compromiso)
        .subscribe((resp) => {
          this.popupCompromisos = false;
          this.getAllCompromisos();
          this.inicializarCompromiso();
          this.compromisosAvances = [];
        });
    }
  }

  getDirecciones() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(44).subscribe(
      result => {
        this.direccionesLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  getAreas() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorIdPadre(this.compromiso.AreaId).subscribe(
      result => {
        this.areasLista = result.Lista;
        //this.cargarOferta();
        //this.programasLista = [];
        //this.OfertaProgramaId = '0';
        this.AreaId = '0';
        // this.cargarPrograma();
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  showAccionCompromiso(data: Compromisos, accion: string) {

    if (accion === 'C') {
      this.inicializarCompromiso();
    }
    else if (accion === 'U') {
      this._compromisoService
        .getCompromisoByCompromisoId(data.CompromisoId)
        .subscribe((resp: any) => {
          this.compromiso = resp;
          this.urlImagenExperiencia = this.compromiso.NombreArchivo;
          this.mostrarMunicipios();
          this.getAreas();
          this.getUsuariosInternos();

        });
    }
    this.accionCompromiso = accion;
    this.popupCompromisos = true;
  }



  borrarCompromiso(CompromisoId) {
    Swal.fire({
      title: 'Borrar Compromiso?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        this._compromisoService.deleteCompromiso(CompromisoId).subscribe(
          (result) => {
            // if (result.OperacionExitosa) {
            //     this.getRedSocialByAgente();
            //     this.servicePNotify.warn('Eliminar', 'Datos eliminados correctamente!');
            // } else {
            //     this.servicePNotify.html(
            //         '<h4>Error al guardar los datos</h4><p>' + result.Mensaje + '</p>',
            //         'error'
            //     );
            // }
            // se puede utilizar esta variable para controler los mensajes del back
            //console.log(result.OperacionExitosa);
            if (result.OperacionExitosa) {
              Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
            }
            else {
              Swal.fire('ERROR!', 'Existen avances registrados, por favor borrar primero los avances', 'error');
            }

            this.getAllCompromisos();
          },
          (error) => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            // swal('Agente Creado', resp.Mensaje, 'success' );
            console.log(<any>error);
          }
        );


      }
    });
  }

  inicializarCompromiso() {
    this.compromiso = {
      CompromisoId: null,
      AnoId: null,
      DependenciaResponsable: null,
      ImportanciaId: null,
      AreaId: null,
      JefeDependencia: null,
      Temas: '',
      Descripcion: '',
      MunicipioId: null,
      TipoReporteId: null,
      Estado: '',
      FechaCompromiso: '',
      FechaLimite: '',
      Observaciones: '',
      UsuarioResponsableReporteId: null,
      UsuarioRegistraId: null,
      FechaRegistro: '',
      FechaActualizada: '',
      DepartamentoId: null,
      tipoCompromisoId: null,
      NombreArchivo: '',
      responsable: null,
    };
    this.valueExperiencia = [];

    this.urlImagenExperiencia = '';
  }

  subidaCompletaPdfExperiencia(e: {
    request: { status: number; response: string };
  }) {
    debugger;
    if (e.request.status === 201) {
      const obj = JSON.parse(e.request.response);
      console.log(obj.Message.split('|')[1]);
      this.compromiso.NombreArchivo = obj.Message.split('|')[1];
      this.urlImagenExperiencia =
        URL_SERVICIOS + '/Medios/Compromisos/' + obj.Message.split('|')[1];
      setTimeout(() => {
        // swal('Archivo cargado', 'Los datos se han subido correctamente!', 'success' );
        // Swal.fire(
        //   'Archivo cargado!',
        //   'Los datos se han subido correctamente!.',
        //   'success'
        // );
      }, 15);
    } else {
      setTimeout(() => {
        // swal('ERROR', 'Los datos NO se han subido correctamente!', 'success' )
        // Swal.fire(
        //   'ERROR!',
        //   'Los datos NO se han subido correctamente!',
        //   'error'
        // );
      }, 15);
    }
  }

  subidaCompletaArchivoAvanceCompromiso(e: {
    request: { status: number; response: string };
  }) {
    const obj = JSON.parse(e.request.response);

    this.compromisosAvance.NombreArchivo = obj.Message.split('|')[1];
    this.urlImagenExperiencia =
      URL_SERVICIOS + '/Medios/Compromisos/' + obj.Message.split('|')[1];
  }

  descargarArchivoCompromisoAvance() {
    console.log(this.compromisosAvance.ReporteAvanceId)
    if (this.compromisosAvance.ReporteAvanceId !== null) {
      this.loading = true;
      this._compromisoService
        .downloadMedioCompromisoAvanceByReporteAvanceId(
          this.compromisosAvance.ReporteAvanceId
        )
        .subscribe(
          (res) => {
            const url = window.URL.createObjectURL(res.body);
            const fileName = res.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.loading = false;
          },
          (error) => {
            this.error = error;
            this.loading = false;
            console.log(<any>error);
          }
        );
    }
  }

  descargarArchivoExperiencia() {
    if (this.compromiso.CompromisoId !== null) {
      this.loading = true;
      this._compromisoService
        .downloadMediosExperienciaById(
          this.compromiso.CompromisoId
        )
        .subscribe(
          (res) => {
            const url = window.URL.createObjectURL(res.body);
            const fileName = res.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.loading = false;
          },
          (error) => {
            this.error = error;
            this.loading = false;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            console.log(<any>error);
          }
        );
    }
  }

  mostrarMunicipios() {
    this._tipoReferenciaService
      .getMunicipiosByDepartamento(this.compromiso.DepartamentoId)
      .subscribe(
        (result) => {
          this.munLista = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  cargarImportancia() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorNombre('MA_COMPROMISO_IMPORTANCIA')
      .subscribe(
        (result) => {
          this.listaImportancia = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }


  cargarJefesDependencia() {
    // this._tipoReferenciaService
    //   .getValorReferenciaPorTipoValorNombre('MA_JEFE_DEPENDENCIA')
    this._compromisoService.getUsuariosInternos()
      .subscribe((resp: any) => {
        this.listaJefesDependencia = resp.Lista.sort((a, b) => a.USU_NOMBRE.localeCompare(b.USU_NOMBRE));
      },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  cargarTipoReporte() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorNombre('MA_COMPROMISO_TIPO_REPORTE')
      .subscribe(
        (result) => {
          this.listaTipoReporte = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  cargarEstado() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorNombre('MA_COMPROMISO_ESTADO')
      .subscribe(
        (result) => {
          this.listaEstado = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  cargarTiposCompromisos() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorNombre('MA_COMPROMISO_TIPO')
      .subscribe(
        (result) => {
          this.listaCompromisoTipo = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  cargarUsuarios() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorNombre('MA_COMPROMISO_ESTADO')
      .subscribe(
        (result) => {
          this.listaEstado = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  getUsuariosInternos() {
    this._compromisoService.getUsuariosInternos()
      .subscribe((resp: any) => {
        this.listaUsuarios = new ArrayStore({ data: resp.Lista.sort((a, b) => a.USU_NOMBRE.localeCompare(b.USU_NOMBRE)), key: 'USU_ID' })

      },
        error => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );

    this.compromisosAvances = [];
  }

  showVolver() {
    this.popupCompromisos = false;
  }

  customizeTooltip = (pointsInfo) => {
    return { text: parseInt(pointsInfo.originalValue) + " Días Restantes" };
  }

  customizeTooltipPorcentaje = (pointsInfo) => {
    return { text: parseInt(pointsInfo.originalValue) + "%" };
  }




  inicializarArchivo() {
    this.compromisosAvancesArchivos = {
      CompromisosAvancesArchivoId: null,
      ReporteAvanceId: null,
      ArchivoId: null,
      ArchivoNombreArchivo: '',
      ArchivoFechaRegistro: '',
      ArchivoUsuarioIdCreacion: null
    };

  }

  showAccionArchivo(accion: string) {
    this.inicializarArchivo();
    this.valueArchivo = [];
    this.popupVisibleRegistroArchivo = true;

  }

  popupHidingRegistroArchivo(event: Event) {
    event.stopPropagation();
    this.popupVisibleRegistroArchivo = false;
  }

  onSubmitRegistroArchivo(event: Event, isValid: any) {
    event.stopPropagation();
    if (isValid) {
      if (this.compromisosAvancesArchivos.ArchivoNombreArchivo === '') {
        Swal.fire(
          'Mensaje',
          'Debe Seleccionar el archivo y hacer clic en el boton subir archivo', 'error'
        );
        return;
      }
      this.compromisosAvancesArchivos.ReporteAvanceId = this.compromisosAvance.ReporteAvanceId;

      this.compromisosAvancesArchivos.ArchivoUsuarioIdCreacion = this.usuId;

      this._compromisoService.addCompromisosAvancesArchivo(this.compromisosAvancesArchivos).subscribe((resp) => {
        this.popupVisibleRegistroArchivo = false;
        this.getCompromisosArchivos(this.compromisosAvancesArchivos.ReporteAvanceId);

      });
    }
  }

  addIdParameterArchivo(e) {
    this.urlArchivo = URL_SERVICIOS + '/ApiRest/Compromisos/MediaUploadCompromisosAvancesArchivo';
  }


  subidaCompletaArchivo(e) {
    if (e.request.status === 201 || e.request.status === 200) {
      const obj = JSON.parse(e.request.response);
      console.log(obj.Message.split('|')[1]);
      this.compromisosAvancesArchivos.ArchivoNombreArchivo = obj.Message.split('|')[1];
      setTimeout(() => {
        Swal.fire(
          'Archivo cargado',
          'Los datos se han subido correctamente!',
          'success'
        );
      }, 15);
    } else {
      setTimeout(() => {
        Swal.fire(
          'ERROR',
          'Los datos NO se han subido correctamente!',
          'success'
        );
      }, 15);
    }
  }

  descargarArchivo(id: number) {
    if (id !== null) {
      this.loading = true;
      this._compromisoService.downloadMediosArchivoById(id).subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res.body);
          const fileName = res.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.loading = false;
        },
        (error) => {
          this.error = error;
          this.loading = false;

        }
      );
    }
  }

  borrarArchivo(Id) {
    Swal.fire({
      title: 'Borrar Archivo?',
      text: 'Ese proceso no se podra revertir!',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        this._compromisoService.deleteCompromisosAvancesArchivo(Id).subscribe(
          (result) => {
            this.getCompromisosArchivos(this.compromisosAvancesArchivos.ReporteAvanceId);
          },
          (error) => {

            console.log(<any>error);
          }
        );
        Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
      }
    });
  }


}
