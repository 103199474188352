import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

// Rutas
import { APP_ROUTES } from './app.routes';

// Modulos
import { PagesModule } from './pages/pages.module';

// Temporal
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Servicios
import { ServiceModule } from './services/service.module';

// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';

import { DxPopupModule, DxDataGridModule, DxScrollViewModule } from 'devextreme-angular';

import { ModaltextComponent } from './components/modaltext/modaltext.component';

import { AgmCoreModule } from '@agm/core';
import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './new-home/home-header/home-header.component';
import { NewHomeComponent } from './new-home/new-home.component';
import { NoticiasComponent } from "./new-home/noticias/noticias.component";
import { EstadisticasComponent } from "./new-home/estadisticas/estadisticas.component";
import { DetalleNoticiasComponent } from "./new-home/detalle-noticias/detalle-noticias.component";
import { CatalogoDocumentalComponent } from "./new-home/catalogo-documental/catalogo-documental.component";
import { DetalleCatalogoComponent } from "./new-home/detalle-catalogo/detalle-catalogo.component";
import { CalendarioComponent } from "./new-home/calendario/calendario.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './utils/app-init';

import { NgxLoadingModule } from 'ngx-loading';
import { ValidarCuentaComponent } from './login/validar-cuenta.component';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ExpSensorialComponent } from './exp-sensorial/exp-sensorial.component';
import { ExpSensorialModule } from './exp-sensorial/exp-sensorial.module';
import { MapaSonoroComponent } from './mapa-sonoro/mapa-sonoro.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { GOOGLE_ANALYTICS_KEY } from './config/config';
import { MatCardModule, MatIconModule, MatTabsModule } from '@angular/material';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ModaltextComponent,
    HomeComponent,
    NewHomeComponent,
    NoticiasComponent,
    EstadisticasComponent,
    DetalleNoticiasComponent,
    HomeHeaderComponent,
    CatalogoDocumentalComponent,
    DetalleCatalogoComponent,
    CalendarioComponent,
    ValidarCuentaComponent,
    ExpSensorialComponent,
    MapaSonoroComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    ExpSensorialModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    NgbModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD1S_KatBI9RiyeU_VunIYDS6tWRr5QfsY',
      libraries: ['geometry', 'places', 'drawing'],
    }),
    BrowserAnimationsModule,
    KeycloakAngularModule,
    NgxLoadingModule.forRoot({}),
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaskModule.forRoot({}),
    NgxGoogleAnalyticsModule.forRoot(GOOGLE_ANALYTICS_KEY)
    
  ],
  providers: [

    // ---- keycloak ----//
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializer,
    //   multi: true,
    //   deps: [KeycloakService]
    // }
    // ---- keycloak ----//
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
