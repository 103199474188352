//export const URL_SERVICIOS = 'http://localhost:3000'; 
// export const   URL_SERVICIOS = 'http://192.168.0.22:40347/';
//public static UrlReportes: string = 'http://localhost:5804/';

// Produccion
export const URL_SERVICIOS = 'https://siartesapi.mincultura.gov.co/';
export const   URL_SERVICIOS_EVENTOS = 'https://serviciospulep.mincultura.gov.co/';
export const UrlReportes = 'https://siartesapi.mincultura.gov.co/';

 
// Pruebas
// export const  URL_SERVICIOS = 'http://172.17.0.244:8058/'; 


// Desarrollo
//export const   URL_SERVICIOS = 'http://localhost:40347/';
//export const   URL_SERVICIOS_EVENTOS = 'http://mcmuisca:8089/ws/';
export const URL_SERVICIOS_SOY_CULTURA_TEST = 'https://soyculturaapi.mincultura.gov.co/api/';

export const USUARIO_SERVICIOS_SOY_CULTURA_TEST = 'pcvargas@mincultura.gov.co';
export const CLAVE_SERVICIOS_SOY_CULTURA_TEST = '79737241';
export const URL_SERVICIOS_SOY_CULTURA = 'http://gestoresapi.presupuesto.co/';

export const URL_SERVICIOS_CONCERTACION = 'https://siartesapi.mincultura.gov.co/';

export const GOOGLE_ANALYTICS_KEY = 'G-JNXEBX75XL'


// export const  UrlReportes = 'http://localhost/reportes/';


export const URL_PULEP_EVENTOS = 'https://mincultura.creangel.com/productionMincultura/Dashboards/14ee4c9d-1894-458d-9e5c-5e43f8c0eb5e'
export const URL_PULEP_PRODUCTORES = 'https://mincultura.creangel.com/productionMincultura/Dashboards/490bb3a0-b565-4ab5-b72f-c9126aa9b273'
export const URL_PULEP_GIROS = 'https://mincultura.creangel.com/productionMincultura/Dashboards/cf2352a1-622a-48fd-b156-bc84c748b94d'
export const URL_SIMUS_ESCUELA = 'https://mincultura.creangel.com/productionMincultura/Dashboards/94546934-a583-4109-8892-c298599356e3'
export const URL_SIMUS_AGRUPACIONES = 'https://mincultura.creangel.com/productionMincultura/Dashboards/a8a4b2be-ca29-4180-9280-254d8f71fd80'
export const URL_SIMUS_AGENTES = 'https://mincultura.creangel.com/productionMincultura/Dashboards/d62d6472-7814-4875-be19-e56c75936fec'
export const URL_SIMUS_ENTIDADES = 'https://mincultura.creangel.com/productionMincultura/Dashboards/d251e530-11f1-48f8-9428-ccd5fdef01e5'