import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css']
})
export class HomeHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  expand(){
    const submenu = document.querySelector("#sub_menu");
    const btn = document.querySelector(".btn-menu");
    if(submenu.className == "sub_menu_collapsed"){
        submenu.classList.remove('sub_menu_collapsed');
        submenu.classList.add('sub_menu');
        btn.classList.add('arrow');
    } else {
        submenu.classList.remove("sub_menu");
        submenu.classList.add("sub_menu_collapsed");
        btn.classList.remove('arrow');
    }
}

}
