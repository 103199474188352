import { Component, OnInit } from '@angular/core';
import { IndicadoresService } from '../../services/indicadores/indicadores.service';
import { Indicador, MetaAvanceIndicador, MetaCuatrenioIndicador, ConsolidadoMetaAvanceIndicador } from '../../models/Indicador.model';
import { IOption } from 'ng-select';
import * as XLSX from 'xlsx';
import { TipoReferenciaService } from '../../services/tipoReferencia/tipo-referencia.service';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { User } from '../../models/user.model';
import { stringify } from 'querystring';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./Indicadores.component.css'],
})
export class IndicadoresComponent implements OnInit {
  jsonData: any;
  worksheet: any;
  storeData: any;
  fileUploaded: File;
  indicadorId;
  AreaId= 0;
  listaConsolidadoIndicadores = [];
  listaAgentesFormacion = [];
  listaIndicadores = [];
  displayListIndicadores: boolean;
  displayCrearIndicador: boolean;
  ocultarCuatrenio: boolean;
  registro: boolean;
  EstiloStep1: string;
  EstiloStep2: string;
  EstiloStep3: string;
  EstiloStep4: string;
  indicador: Indicador;
  consolidadoIndicador: ConsolidadoMetaAvanceIndicador;
  popupVisibleConsolidadoIndicador: boolean;
  popupVisible: boolean;
  displayFormularioConsolidadoIndicador: boolean;
  displayListaConsolidadoProyecto: boolean;
  CumplimientoMeta = 0;
  RecursosEjecutados = 0;
  metaAvanceIndicador: MetaAvanceIndicador;
  metaCuatrenioIndicador: MetaCuatrenioIndicador;
  MesesLista: Array<IOption>;
  EstadoLista: Array<IOption>;
  PeriodicidadLista: Array<IOption>;
  ReporteLista: Array<IOption>;
  ObjetivoLista: Array<IOption>;
  UnidadesLista: Array<IOption>;
  EstrategiaLista: Array<IOption>;
  AccionLista: Array<IOption>;
  TipoIndicadorLista: Array<IOption>;
  AreaLista: Array<IOption>;
  ProgramasLista: Array<IOption>;
  AnoLista: Array<IOption>;
  EstadosLista: Array<IOption>;
  EstadosConsolidadoLista: Array<IOption>;
  depLista: Array<IOption>;
  munListaAvanceIndicador: Array<IOption>;
  ListadeResgistrosDecargues:Array<IOption>;
  munLista: Array<IOption>;
  experiencia: boolean;
  displayCrearAgenteExperiencia: boolean;
  displayListAgenteExperiencia: boolean;
  formacion: boolean;
  displayListAgenteFormacion: boolean;
  displayCrearAgenteFormacion: boolean;
  operacion = 'C';
  usuario: User;
  usuId: number;
  roles: any;
  role: string;
  perfilId: number;
  gestionaIndicadores: boolean;
  // Experiencia
  listaAgentesExperiencia = [];
  // fin esperiencia

//reportes seleccionados
  selectedReportes: number[] = []; 
  selectedReportesnombre: string[] = []; 

  constructor(public _indicadoresService: IndicadoresService, public _tipoReferenciaService: TipoReferenciaService, public _usuarioService: UsuarioService) {
    this.ocultarCuatrenio = false;
    this.popupVisibleConsolidadoIndicador = false;
    this.displayFormularioConsolidadoIndicador = false;
    this.displayCrearIndicador = false;
    this.displayListIndicadores = true;
    this.registro = true;
    this.formacion = false;
    this.displayListAgenteFormacion = true;
    this.displayCrearAgenteFormacion = false;
    this.experiencia = false;
    this.displayListAgenteExperiencia = true;
    this.displayCrearAgenteExperiencia = false;
    // tslint:disable-next-line: max-line-length
    this.indicador = new Indicador(null, null, null, null, null, '', '', '', null, '', null, '', 0, '', null, null, null,  null, '', null, '', '', null, null, null, null);
    this.consolidadoIndicador = new ConsolidadoMetaAvanceIndicador(null, null, null, null, null, null, '', '', null, '', null, '', null, '', '', '', '', null, null, null, null, null);
    this.metaAvanceIndicador = new MetaAvanceIndicador(null, null, null, '', null, '', '', '', null, '', null, null, null, null,null);
    this.metaCuatrenioIndicador = new MetaCuatrenioIndicador(null, null, null, null, '', '', null, '');
    this.EstiloStep1 = 'active';
    this.EstiloStep2 = '';
    this.EstiloStep3 = '';
    this.EstiloStep4 = '';
    /////////////////////
    // Usuarios        //
    /////////////////////
    const user: any = this._usuarioService.getCurrentUser();
    this.usuario = JSON.parse(user);
    this.usuId = this.usuario.USU_ID;
    this.roles = this.usuario.Perfiles;
    if (this.roles.length > 0) {
      this.role = this.roles[0].PER_NOMBRE;
      this.perfilId = this.roles[0].PER_ID;
      if(this.perfilId ==  1 || this.usuId == 2041){
        this.gestionaIndicadores = true;
      } else {
        this.gestionaIndicadores = false;
      }
    }
  }

  ngOnInit() {
    this.getDep();
    this.cargarIndicadores();
    this.cargarPeriodicidad();
    this.cargarReporte();
    this.cargarObjetivo();
    this.cargarUnidadMedidad();
    this.cargarTipoIndicador();
    this.cargarAnos();
    this.cargarEstado();
    this.cargarArea();
    this.cargarProgramas();
    this.cargarMeses();
    this.cargarEstados();

  }

  getIndicadorByIndicadorId(id: any) {

    debugger;
    this._indicadoresService.getIndicadorByIndicadorId(id).subscribe(
      (result) => {
        this.indicador = result;
        this.cargarObjetivo();
        this.cargarEstrategia(this.indicador.ObjetivoId);
        this.cargarAccion(this.indicador.EstrategiaId);
        this.cargarAvancesIndicadores(this.indicador.IndicadorId);
        this.cargarCuatrenioIndicadores(this.indicador.IndicadorId);
        this.mostrarMunicipios();
        this.cargarRegistrosdeCargue(this.indicador.IndicadorId);


      },
      (error) => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }

    );
  }




  getDep() {
    this._tipoReferenciaService.getDepartamentos().subscribe(
      (result) => {
        // debugger;
        this.depLista = result.Lista;
      },
      (error) => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }


  mostrarMunicipios() {
    this._tipoReferenciaService
      .getMunicipiosByDepartamento(this.indicador.DepartamentoId)
      .subscribe(
        (result) => {
          // debugger;
          this.munLista = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  mostrarMunicipiosAvanceIndicador() {
    this._tipoReferenciaService
      .getMunicipiosByDepartamento(this.metaAvanceIndicador.DepartamentoId)
      .subscribe(
        (result) => {
          // debugger;
          this.munListaAvanceIndicador = result.Lista;
        },
        (error) => {
          // this.error = error;
          // if (error.statusText === 'Unauthorized') {
          //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
          //     this.authenticationService.logout().subscribe(response => { });
          //     this.storageService.logout();
          // }
          console.log(<any>error);
        }
      );
  }

  cargarEstado() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorNombre('MA_INDICADORES_ESTADOS').subscribe(
      result => {
        this.EstadosLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarMeses() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorNombre('MA_MES').subscribe(
      result => {
        this.MesesLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarEstados() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3100).subscribe(
      result => {
        this.EstadosConsolidadoLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarPeriodicidad() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3061).subscribe(
      result => {
        this.PeriodicidadLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarAnos() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorNombre('MA_ANO').subscribe(
      result => {
        this.AnoLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarReporte() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3118).subscribe(
      result => {
        this.ReporteLista = result.Lista;

      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarObjetivo() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3119).subscribe(
      result => {
        this.ObjetivoLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarUnidadMedidad() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3122).subscribe(
      result => {
        this.UnidadesLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarEstrategia(ObjetivoId: number) {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorIdPadre(ObjetivoId).subscribe(
      result => {
        this.EstrategiaLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarAccion(EstrategiaId: number) {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorIdPadre(EstrategiaId).subscribe(
      result => {
        this.AccionLista = result.Lista;
        console.log(this.AccionLista);
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarTipoIndicador() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3062).subscribe(
      result => {
        this.TipoIndicadorLista = result.Lista;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarArea() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorIdPadre(1261).subscribe(
      result => {
        this.AreaLista = result.Lista.filter(item=>item.estado == "Activo" );;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarProgramas() {
    this._tipoReferenciaService.getValorReferenciaPorTipoValorId(3123).subscribe(
      result => {
        this.ProgramasLista = result.Lista.filter(item=>item.estado == "Activo" );;
      },
      error => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  cargarIndicadores() {
    this._indicadoresService.cargarIndicadores().subscribe((resp: any) => {
      this.listaIndicadores = resp.Lista;
      // this.appointmentsData = resp.Lista;
      // this.cargarFicha();
      // this.loading = false;
    });
  }

  showPanel2(sw: string, id: any) {
    this.registro = false;
     this.experiencia = false;
     this.formacion = false;
    if (sw === 'R') {
      // Crear Usuario
      this.registro = true;
      this.EstiloStep1 = 'active';
      this.EstiloStep2 = '';
      this.EstiloStep3 = '';
      this.EstiloStep4 = '';
    } else if (sw === 'P' && this.indicador.IndicadorId != null) {
    // } else if (sw === 'P') {
      // Actualizar Usuario
      this.experiencia = true;
      this.EstiloStep1 = 'complete';
      this.EstiloStep2 = 'active';
      this.EstiloStep3 = '';
      this.EstiloStep4 = '';
    } else if (sw === 'O' && this.indicador.IndicadorId != null) {
    // } else if (sw === 'O') {
      // Volver al listado
      this.formacion = true;
      this.EstiloStep1 = 'complete';
      this.EstiloStep2 = 'complete';
      this.EstiloStep3 = 'active';
      this.EstiloStep4 = '';
    } else if (this.indicador.IndicadorId === null) {
      this.registro = true;
    }
  }

  showPanel(sw, id) {
    if (sw === 'CA') { // Crear Usuario
      this.displayCrearIndicador = true;
      this.displayListIndicadores = false;
    } else if (sw === 'C') { // Crear Usuario
      this.displayListIndicadores = false;
      this.displayCrearIndicador = true;
       this.inicializarModelo();
    } else if (sw === 'U') { // Actualizar Usuario
      this.displayListIndicadores = false;
      this.displayCrearIndicador = true;
       this.getIndicadorByIndicadorId(id);
       this.operacion = 'U';
    } else if (sw === 'V') {// Retornar al Listado
      this.displayListIndicadores = true;
      this.displayCrearIndicador = false;
      // this.displayEntidadAreas = false;

       this.inicializarModelo();
      // recargar la tabla
       this.cargarIndicadores();
    }
  }


  

  onSubmit(isValid: any, paso: string) {
    if (isValid) {
      debugger;



      // this.entidad.TipoCampoAccion = [];
      // for (let i = 0; i < this.selectedItemsAreaPrincipal.length; i++) {
      //   this.entidad.TipoCampoAccion.push(this.selectedItemsAreaPrincipal[i].id);
      // }

      // this.entidad.OtrasAreasCampoAccion = this.dataSourceOtrasOcupaciones;

      this._indicadoresService.crearIndicador(this.indicador, this.selectedReportes)
        .subscribe(resp => {
          this.indicador.IndicadorId = this._indicadoresService.IndicadorId;
          //this.showPanel('V', null);

          
        }
        );

    
      // }

      // this.registro = false;
      // this.experiencia = false;
      // //this.formacion = false;
      // if (paso === 'R') {
      //   // Crear Usuario
      //   this.registro = true;
      //   this.EstiloStep1 = 'active';
      //   this.EstiloStep2 = '';
      //   this.EstiloStep3 = '';
      //   this.EstiloStep4 = '';
      // } else if (paso === 'P') {
      //   // Actualizar Usuario
      //   this.experiencia = true;
      //   this.EstiloStep1 = 'complete';
      //   this.EstiloStep2 = 'active';
      //   this.EstiloStep3 = '';
      //   this.EstiloStep4 = '';
      // } else if (paso === 'O') {
      //   // Volver al listado de contratistas
      //   // this.formacion = true;
      //   this.EstiloStep1 = 'complete';
      //   this.EstiloStep2 = 'complete';
      //   this.EstiloStep3 = 'active';
      //   this.EstiloStep4 = '';
      // }
    }
  }

  inicializarModelo() {
    this.indicador = {
      IndicadorId: null,
        ReporteId: null,
        ObjetivoId: null,
        EstrategiaId: null,
        AccionId: null,
        AccionNombre: '',
        IndicadorNombre: '',
        IndicadorUnidadMedida: '',
        PeriodicidadId: null,
        PeriodicidadNombre: '',
        TipoIndicadorId: null,
        TipoIndicadorNombre: '',
        AreaId: null,
        AreaNombre: '',
        IndicadorLineaBase: null,
        CuentaUsuarioIdGestiona: null,
        metaAvanceIndicador: [],
        metaCuatrenioIndicador: [],
        IndicadorFechaModificacion: '',
        EstadoId: null,
        IndicadorFormula: '',
        IndicadorDescripcion: '',
        MunicipioId: null,
        DepartamentoId: null,
        ValorProgramado: null,
        ValorProgramadoProgramacion: null
    };
    this.inicializarModeloExperiencia();
    this.inicializarModeloFormacion();
    this.listaAgentesExperiencia = null;
    this.listaAgentesFormacion = null;
    this.displayCrearAgenteExperiencia = false;
    this.displayListAgenteExperiencia = true;
    this.displayCrearAgenteFormacion = false;
    this.displayListAgenteFormacion = true;
    this.inicializarModeloConsolidadoIndicador();
  }

  borrarIndicador(id: any) {
    Swal.fire({
      title: 'Borrar Registro?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        debugger;
        this._indicadoresService.deleteInidicador(id).subscribe(
          (result) => {
            // if (result.OperacionExitosa) {
            //     this.getRedSocialByAgente();
            //     this.servicePNotify.warn('Eliminar', 'Datos eliminados correctamente!');
            // } else {
            //     this.servicePNotify.html(
            //         '<h4>Error al guardar los datos</h4><p>' + result.Mensaje + '</p>',
            //         'error'
            //     );
            // }
            Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
            this.cargarIndicadores();
          },
          (error) => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            // swal('Agente Creado', resp.Mensaje, 'success' );
            Swal.fire('No puede ser borrado!', 'Este registro fue borrado.', 'warning');
            console.log(<any>error);
          }
        );
        
      }
    });
  }

  ///////////////////////////////////////////
  // Consolidado Indicador Avance Meta     //
  ///////////////////////////////////////////


  borrarConsolidadoIdicador(id: any) {
    Swal.fire({
      title: 'Borrar Registro?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        debugger;
        this._indicadoresService.deleteConsolidadoIndicador(id).subscribe(
          (result) => {
            // if (result.OperacionExitosa) {
            //     this.getRedSocialByAgente();
            //     this.servicePNotify.warn('Eliminar', 'Datos eliminados correctamente!');
            // } else {
            //     this.servicePNotify.html(
            //         '<h4>Error al guardar los datos</h4><p>' + result.Mensaje + '</p>',
            //         'error'
            //     );
            // }
            this.getAllConsolidadoIndicadorAvance(this.indicadorId);
          },
          (error) => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            // swal('Agente Creado', resp.Mensaje, 'success' );
            console.log(<any>error);
          }
        );
        Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
        this.cargarRegistrosdeCargue(this.indicador.IndicadorId);
      }
    });
  }

  calcularConsolidado(){
    debugger;
    
    this._indicadoresService
    .calcularConsolidado(this.consolidadoIndicador.IndicadorId, this.consolidadoIndicador.AreaId)
    .subscribe((result) => {
      let _id;
      let _fecha;
      let _avanceCualitativo;
      let _periodoId;
      let _estadoId;
      let _motivo;
      if(this.consolidadoIndicador.ConsolidadoAvanceIndicadorId != null){
        _id = this.consolidadoIndicador.ConsolidadoAvanceIndicadorId;
        _fecha = this.consolidadoIndicador.Fecha;
        _avanceCualitativo = this.consolidadoIndicador.AvanceCualitativo;
        _periodoId = this.consolidadoIndicador.PeriodoId;
        _estadoId = this.consolidadoIndicador.EstadoId;
        _motivo = this.consolidadoIndicador.Motivo;

      }
      this.consolidadoIndicador = result;
      this.consolidadoIndicador.ConsolidadoAvanceIndicadorId = _id;
      this.consolidadoIndicador.Fecha = _fecha;
      this.consolidadoIndicador.AvanceCualitativo = _avanceCualitativo;
      this.consolidadoIndicador.PeriodoId = _periodoId;
      this.consolidadoIndicador.EstadoId = _estadoId;
      this.consolidadoIndicador.Motivo = _motivo;
    });
    
  }

  getAllConsolidadoIndicadorAvance(IndicadorId: number) {
   
    this.popupVisibleConsolidadoIndicador = true;
    this.displayListaConsolidadoProyecto = true;
    this.displayFormularioConsolidadoIndicador = false;
    this.getIndicadorByIndicadorId(IndicadorId);
    
    this._indicadoresService
      .getAllConsolidadoAvancesIndicador(IndicadorId)
      .subscribe((resp: any) => {
        this.listaConsolidadoIndicadores = resp.Lista;
      });

      this.indicadorId = IndicadorId;
  }

  showAccionConsolidadoProyecto(id: number, accion: string) {
    
    // debugger;
    // this.popupVisibleProyecto = false;
    // this.inicializarModeloFuncion();
    // this.mostrarMunicipiosProyectoFuncion();
    // this.cargarDisciplinaArtisticaProyectoFuncion();
    // this.cargarProyectoFuncionRealizada();
    // this.cargarProyectoFuncionAbierta();
    // this.cargarProyectoFuncionGrupoPlanta();
    if (accion === 'C') {
      // Crear Usuario
       this.inicializarModeloConsolidadoIndicador();
    }

    if (accion === 'U') {
      this.getConsolidadoIndicadorById(id);
    }
    this.displayFormularioConsolidadoIndicador = true;
    this.displayListaConsolidadoProyecto = false;
 
    //this.accionProyectoFuncion = accion;
  }

  showVolverProyectosFuncion() {
    this.displayFormularioConsolidadoIndicador = false;
    //this.showAccionProyecto(this.proyecto.ProyectoId, 'U');
    this.displayListaConsolidadoProyecto = true;
  }

  inicializarModeloConsolidadoIndicador(){
    this.consolidadoIndicador = {
      ConsolidadoAvanceIndicadorId: null,
      IndicadorId: this.indicadorId,
      AreaId: null,
      Avance: null,
      RecursosEjecutados: null,
      PeriodoId: null,
      Fecha: '',
      AvanceCualitativo: '',
      UsuarioRegistraId: null,
      FechaRegistro: '',
      EstadoId: null,
      Motivo: null,
      UsuarioAdministradorId: null,
      AreaNombre: '',
      PeriodoNombre: '',
      EstadoNombre: '',
      UsuarioRegistraNombre: '',
      MetaProgramada: null,
      NumeroBeneficiarios: null,
      ValorProgramado: null,
      PorcentajeCumplimientoMeta: null,
      PorcentajeCumplimientoEjecucion: null,
    };
  }

  onSubmitConsolidadoIndicador(isValid: any) {
    if (isValid) {
      // debugger;
      // this.salvarProyectofuncion();
      if(this.usuId == 2041){
        this.consolidadoIndicador.UsuarioAdministradorId = this.usuId;
      }

      this.consolidadoIndicador.UsuarioRegistraId = this.usuId;
      if(this.consolidadoIndicador.RecursosEjecutados > this.consolidadoIndicador.ValorProgramado){
        
        Swal.fire('Error', 'Los recursos ejecutados superan los recursos programados', 'error');
        return;
      }


      
      this._indicadoresService
      .crearConsolidadoAvanceIndicador(this.consolidadoIndicador)
      .subscribe((resp) => {
        
          this.displayFormularioConsolidadoIndicador = false;
          this.displayListaConsolidadoProyecto = true;
          this.getAllConsolidadoIndicadorAvance(this.indicadorId);
        
        
        
        //this.showPanelExperiencia('V', null);
      });
    }
  }

  getConsolidadoIndicadorById(id: any) {
    this._indicadoresService.getConsolidadoAvanceIndicadorById(id).subscribe(
      (result) => {
        this.consolidadoIndicador = result;
        //this.mostrarMunicipiosAvanceIndicador();
        
        if(this.consolidadoIndicador.NumeroBeneficiarios > 0 && this.consolidadoIndicador.MetaProgramada > 0 )
        {
          this.CumplimientoMeta = (this.consolidadoIndicador.NumeroBeneficiarios /this.consolidadoIndicador.MetaProgramada) * 100;
        }

        if(this.consolidadoIndicador.RecursosEjecutados > 0 && this.consolidadoIndicador.ValorProgramado > 0 )
        {
          this.RecursosEjecutados = (this.consolidadoIndicador.RecursosEjecutados /this.consolidadoIndicador.ValorProgramado) * 100;
        }

        this.getIndicadorByIndicadorId(id);
      },
      (error) => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }



  //////////////////////////
  //      Avance Meta     //
  //////////////////////////
  showPanelExperiencia(sw: string, id: any) {
    if (sw === 'C') {
      // Crear Usuario
      this.displayListAgenteExperiencia = false;
      this.displayCrearAgenteExperiencia = true;
       this.inicializarModeloExperiencia();
    } else if (sw === 'U') {
      // Actualizar Usuario
      this.displayListAgenteExperiencia = false;
      this.displayCrearAgenteExperiencia = true;
      this.getAgenteExperienciaById(id);
      this.operacion = 'U';
    } else if (sw === 'V') {
      // Volver al listado de contratistas
      this.displayListAgenteExperiencia = true;
      this.displayCrearAgenteExperiencia = false;
       this.inicializarModeloExperiencia();
      // recargar la tabla
       this.cargarAvancesIndicadores(this.indicador.IndicadorId);
    }
  }

  cargarAvancesIndicadores(id: number) {
    debugger;
    this._indicadoresService.cargarAvancesIndicadores(id).subscribe((resp: any) => {
      this.listaAgentesExperiencia = resp.Lista;
    });
    console.log(this.listaAgentesExperiencia);
  }

  onSubmitExperiencia(isValid: any, paso: any) {

        this._indicadoresService
          .crearAvanceIndicador(this.metaAvanceIndicador)
          .subscribe((resp) => {
            this.showPanelExperiencia('V', null);
          });
  }

  inicializarModeloExperiencia() {
    this.metaAvanceIndicador = {
      MetaAvanceId: null,
        IndicadorId: this.indicador.IndicadorId,
        MetaAvanceMeta: null,
        MetaAvanceFechaMeta: '',
        MetaAvanceAvanceCuantitativo: null,
        MetaAvanceAvanceCualitativo: '',
        MetaAvanceFechaAvance: '',
        MetaAvanceObservaciones: '',
        CuentaUsuarioIdGestiona: null,
        MetaAvanceFechaModificacion: '',
        DepartamentoId: null,
        MunicipioId: null,
        ValorProgramado: null,
        AreaId: null,
        ProgramaID:null,
    };
  }

  getAgenteExperienciaById(id: any) {
    this._indicadoresService.getAvanceIndicadorByAvanceIndicadorId(id).subscribe(
      (result) => {
        this.metaAvanceIndicador = result;
        this.mostrarMunicipiosAvanceIndicador();
        this.cargarRegistrosdeCargue(this.indicador.IndicadorId);
      },
      (error) => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  borrarAgenteExperiencia(id: any) {
    debugger;
    Swal.fire({
      title: 'Borrar Registro?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        this._indicadoresService.deleteAgenteExperiencia(id).subscribe(
          (result) => {

            if (result.OperacionExitosa == true) {
              Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
            }
            else{

              Swal.fire('No puede ser borrado!', result.Mensaje, 'warning');
            }
            // if (result.OperacionExitosa) {
            //     this.getRedSocialByAgente();
            //     this.servicePNotify.warn('Eliminar', 'Datos eliminados correctamente!');
            // } else {
            //     this.servicePNotify.html(
            //         '<h4>Error al guardar los datos</h4><p>' + result.Mensaje + '</p>',
            //         'error'
            //     );
            // }
            this.cargarAvancesIndicadores(this.indicador.IndicadorId);
          },
          (error) => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            // swal('Agente Creado', resp.Mensaje, 'success' );
            console.log(<any>error);
          }
        );
      //  Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
      }
    });
  }

  /////////////////////////////
  //      Cargar Excel       //
  /////////////////////////////

  uploadedFile(event) {
    
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  } 

  readExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      debugger;
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
    }
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  readAsJson() {
    debugger;
    this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
    if (this.jsonData !== null) {
      for (let i = 0; i < this.jsonData.length; i++) {
        this.jsonData[i].IndicadorId = this.indicador.IndicadorId;
      }
    }
    //this.jsonData = JSON.stringify(this.jsonData);
     //const data: Blob = new Blob([this.jsonData], { type: "application/json" });
    console.log(this.jsonData);
    // console.log(data);
    // FileSaver.saveAs(data, "JsonFile" + new Date().getTime() + '.json');  

    this._indicadoresService.salvarProgramacionRegionalizada(this.jsonData)
    .subscribe(
      resp => {
        this.cargarAvancesIndicadores(this.indicador.IndicadorId);
        this.cargarRegistrosdeCargue(this.indicador.IndicadorId);
        }
    );

    this.popupVisible = false;
    this.cargarAvancesIndicadores(this.indicador.IndicadorId);
    this.cargarRegistrosdeCargue(this.indicador.IndicadorId);
    //this.OfertaProgramaId = null;

  }

  CargarArchivoRegionalizacionPrograma() {
    this.popupVisible = true;
    // this.OfertaProgramaId = id;
    // this.getIndicadoresByOfertaProgramaId();
    // this._ofertaService.cargarOfertaProgramaRegionalizadoByProgramaId(id).subscribe((resp: any) => {
    //   this.programaRegionalizacion = resp.Lista;
    // });
  }

  /////////////////////////////
  //      Cuatrenio Meta     //
  /////////////////////////////

  showPanelFormacion(sw: string, id: any) {
    if (sw === 'C') {
      // Crear Usuario
      this.displayListAgenteFormacion = false;
      this.displayCrearAgenteFormacion = true;
      this.inicializarModeloFormacion();
    } else if (sw === 'U') {
      // Actualizar Usuario
      this.displayListAgenteFormacion = false;
      this.displayCrearAgenteFormacion = true;
      this.getAgenteFormacionById(id);
      this.operacion = 'U';
    } else if (sw === 'V') {
      // Volver al listado de contratistas
      this.displayListAgenteFormacion = true;
      this.displayCrearAgenteFormacion = false;
      this.inicializarModeloFormacion();
      // recargar la tabla
      this.cargarCuatrenioIndicadores(this.indicador.IndicadorId);
    }
  }

  getAgenteFormacionById(id: any) {
    this._indicadoresService.getMetaCuatrenioIndicadorById(id).subscribe(
      (result) => {
        this.metaCuatrenioIndicador = result;
      },
      (error) => {
        // this.error = error;
        // if (error.statusText === 'Unauthorized') {
        //     this.servicePNotify.error('TYC', 'Se perdio la sesión, por favor loguearse de nuevo', '');
        //     this.authenticationService.logout().subscribe(response => { });
        //     this.storageService.logout();
        // }
        console.log(<any>error);
      }
    );
  }

  inicializarModeloFormacion() {
    this.metaCuatrenioIndicador = {

      MetaCuatrienioId: null,
        IndicadorId: this.indicador.IndicadorId,
        AnoId: null,
        MetaCuatrienioMeta: null,
        MetaCuatrienioDescripcion: '',
        MetaCuatrienioObservacion: '',
        CuentaUsuarioIdGestiona: null,
        MetaCuatrienioFechaModificacion: '',
    };
  }

  cargarCuatrenioIndicadores(id: number) {
    this._indicadoresService.cargarCuatrenioIndicadores(id).subscribe((resp: any) => {
      this.listaAgentesFormacion = resp.Lista;
    });
  }

  onSubmitFormacion(isValid: any, paso: any) {

      // if (isValid) {
        this._indicadoresService
          .crearMetaCuatrenioIndicador(this.metaCuatrenioIndicador)
          .subscribe((resp) => {
            this.showPanelFormacion('V', null);
          });
      //}
  }




  borrarAgenteFormacion(id: any) {
    debugger;
    Swal.fire({
      title: 'Borrar Registro?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        this._indicadoresService.deleteAgenteFormacion(id).subscribe(
          (result) => {
            // if (result.OperacionExitosa) {
            //     this.getRedSocialByAgente();
            //     this.servicePNotify.warn('Eliminar', 'Datos eliminados correctamente!');
            // } else {
            //     this.servicePNotify.html(
            //         '<h4>Error al guardar los datos</h4><p>' + result.Mensaje + '</p>',
            //         'error'
            //     );
            // }
            this.cargarCuatrenioIndicadores(this.indicador.IndicadorId);
          },
          (error) => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            // swal('Agente Creado', resp.Mensaje, 'success' );
            console.log(<any>error);
          }
        );
        Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
      }
    });
  }

  onCheckboxChange(event: any) {
    debugger;
    const value = event.target.value;
    const nombre = event.target.nextSibling.nodeValue;
    if (event.target.checked) {
      // Agregar a la lista si está seleccionado
      this.selectedReportes.push(Number(value));
      this.AccionLista.push((nombre));

    } else {
      // Eliminar de la lista si está deseleccionado
      const index = this.selectedReportes.indexOf(Number(value));
      if (index > -1) {
        this.selectedReportes.splice(index, 1);
        this.selectedReportesnombre.push((nombre));
      }
    }
    console.log(this.selectedReportes);

    //this.indicador.ReporteId = this.selectedReportes; // Actualizar el modelo con la lista de seleccionados
  }


//registro de cargues realizados de regionalizacion

cargarRegistrosdeCargue(id: any){
  debugger;
  this._indicadoresService.getAllRegistrosdeCargue(id).subscribe(
    (result) => {
      // debugger;
      this.ListadeResgistrosDecargues = result.Lista;
    },
    (error) => {
  
      console.log(<any>error);
    }
  );
  }





  borrarRegistroCargue(id: any) {
    debugger;
    Swal.fire({
      title: 'Borrar Registro?',
      text: 'Ese proceso no se podra revertir!',
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar Esto!',
    }).then((result) => {
      if (result.value) {
        this._indicadoresService.deleteRegistroCargue(id).subscribe(
          (result) => {

            if (result.OperacionExitosa == true) {
              Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
            }
            else{

              Swal.fire('No puede ser borrado!', result.Mensaje, 'warning');
            }
           
            this.cargarAvancesIndicadores(this.indicador.IndicadorId);
            this.cargarRegistrosdeCargue(this.indicador.IndicadorId);
          },
          (error) => {
          
            console.log(<any>error);
          }
        );
     
      }
    });
  }

}
