import { Component, OnInit } from '@angular/core';
import {URL_PULEP_EVENTOS, URL_PULEP_GIROS, URL_PULEP_PRODUCTORES, URL_SIMUS_AGENTES, URL_SIMUS_AGRUPACIONES, URL_SIMUS_ENTIDADES, URL_SIMUS_ESCUELA} from '../../config/config'
@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css']
})
export class EstadisticasComponent implements OnInit {

  URL_PULEP_EVENTOS = URL_PULEP_EVENTOS
  URL_PULEP_PRODUCTORES = URL_PULEP_PRODUCTORES
  URL_PULEP_GIROS = URL_PULEP_GIROS
  URL_SIMUS_ESCUELA = URL_SIMUS_ESCUELA
  URL_SIMUS_AGRUPACIONES = URL_SIMUS_AGRUPACIONES
  URL_SIMUS_AGENTES = URL_SIMUS_AGENTES
  URL_SIMUS_ENTIDADES = URL_SIMUS_ENTIDADES
  constructor() { }

  ngOnInit() {
  }

}
